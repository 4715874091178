<template>
  <div class="container">
    <swiper :modules="modules" :slides-per-view="swiperOptions.slidesPerView" :autoplay="{delay: 1000,disableOnInteraction: false,}"
            :speed="swiperOptions.autoplaySpeed" :breakpoints="swiperOptions.breakpoints" :space-between="50"
            @swiper="onSwiper" @slideChange="onSlideChange">
      <template v-for="(imageUrl, index) in imageUrls" :key="index">
        <swiper-slide v-if="mediaType[index] != 'VIDEO'">
          <a :href="permalinks[index]" target="_blank">
            <img :src="imageUrl" :alt="mediaType[index]">
          </a>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>


<script>
import {ref, onMounted} from 'vue';
import axios from 'axios';
import {Swiper, SwiperSlide} from 'swiper/vue';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';

export default {
  components: {
    Swiper,
    SwiperSlide,
    Autoplay
  },
  setup() {


    const imageUrls = ref([]);
    const permalinks = ref([]);
    const mediaType = ref([]);

    const fetchMediaData = async () => {
      try {
        const response = await axios.get('/api/getMedia.php');

        imageUrls.value = response.data.map(item => item.media_url);
        permalinks.value = response.data.map(item => item.permalink);
        mediaType.value = response.data.map(item => item.media_type);
      } catch (error) {
        console.error('Error fetching media data:', error);
      }
    };

    const onSwiper = (swiper) => {
      console.log(swiper);
    };

    const onSlideChange = () => {
      console.log('slide change');
    };

    onMounted(() => {
      fetchMediaData();
    });

    return {
      imageUrls,
      permalinks,
      mediaType,
      onSwiper,
      onSlideChange,
      modules: [Autoplay, Pagination, Navigation],
      swiperOptions: {
        slidesPerView: 4, // Başlangıçta masaüstü için 4 slayt görüntülenir
        spaceBetween: 50, // Slaytlar arasındaki boşluk
        breakpoints: {
          300: { // 300 piksel genişlik eşiği (mobil)
            slidesPerView: 1, // 768 piksel veya daha düşük genişlikte 2 slayt görüntülenir
          },
          768: { // 768 piksel genişlik eşiği (mobil)
            slidesPerView: 2, // 768 piksel veya daha düşük genişlikte 2 slayt görüntülenir
          },
          900: { // 900 piksel genişlik eşiği (masaüstü)
            slidesPerView: 4, // 900 piksel veya daha yüksek genişlikte 4 slayt görüntülenir
          }
        },
        autoplay:true,
        autoplaySpeed: 2000, // Otomatik oynatma hızı (milisaniye cinsinden)
      },
    };
  },
};
</script>
