import 'jquery'
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import Vue from 'vue';


import { createApp } from 'vue';
// import the root component App from a single-file component.
import App from './component/swiper.vue';

const app = createApp(App);

app.mount('#app');

(function($){
    jQuery.fn.menuzord = function(options){
        var settings;
        $.extend( settings = {
            showSpeed: 300,
            hideSpeed: 300,
            trigger: "hover",
            showDelay: 0,
            hideDelay: 0,
            effect: "fade",
            align: "left",
            responsive: true,
            animation: "none",
            indentChildren: true,
            indicatorFirstLevel: "+",
            indicatorSecondLevel: "+",
            scrollable: false,
            scrollableMaxHeight: '100%'
        }, options);

        // variables
        var menu_container = $(this);
        var menu = $(menu_container).children(".menuzord-menu");
        var menu_li = $(menu).find("li");
        var showHideButton;
        var mobileWidthBase = 1199;
        var bigScreenFlag = 2000; // a number greater than "mobileWidthBase"
        var smallScreenFlag = 200; // a number less than "mobileWidthBase"

        // dropdown/megamenu indicators
        $(menu).children("li").children("a").each(function(){
            if($(this).siblings(".dropdown, .megamenu").length > 0){
                $(this).append("<span class='indicator'>" + settings.indicatorFirstLevel + "</span>");
            }
        });
        $(menu).find(".dropdown").children("li").children("a").each(function(){
            if($(this).siblings(".dropdown").length > 0){
                $(this).append("<span class='indicator'>" + settings.indicatorSecondLevel + "</span>");
            }
        });

        // navigation alignment
        if(settings.align == "right"){
            $(menu).addClass("menuzord-right");
        }

        // dropdown indentation (mobile mode)
        if(settings.indentChildren){
            $(menu).addClass("menuzord-indented");
        }

        // responsive behavior
        if(settings.responsive){
            $(menu_container).addClass("menuzord-responsive").prepend("<a href='javascript:void(0)' class='showhide'><em></em><em></em><em></em></a>");
            showHideButton = $(menu_container).children(".showhide");
        }

        // scrollable menu
        if(settings.scrollable){
            if(settings.responsive){
                $(menu).css("max-height", settings.scrollableMaxHeight).addClass("scrollable").append("<li class='scrollable-fix'></li>");
            }
        }

        // shows a dropdown
        function showDropdown(item){
            if(settings.effect == "fade")
                $(item).children(".dropdown, .megamenu").stop(true, true).delay(settings.showDelay).fadeIn(settings.showSpeed).addClass(settings.animation);
            else
                $(item).children(".dropdown, .megamenu").stop(true, true).delay(settings.showDelay).slideDown(settings.showSpeed).addClass(settings.animation);
        }

        // hides a dropdown
        function hideDropdown(item){
            if(settings.effect == "fade")
                $(item).children(".dropdown, .megamenu").stop(true, true).delay(settings.hideDelay).fadeOut(settings.hideSpeed).removeClass(settings.animation);
            else
                $(item).children(".dropdown, .megamenu").stop(true, true).delay(settings.hideDelay).slideUp(settings.hideSpeed).removeClass(settings.animation);
            $(item).children(".dropdown, .megamenu").find(".dropdown, .megamenu").stop(true, true).delay(settings.hideDelay).fadeOut(settings.hideSpeed);
        }

        // landscape mode
        function landscapeMode(){
            $(menu).find(".dropdown, .megamenu").hide(0);
            if(navigator.userAgent.match(/Mobi/i) || window.navigator.msMaxTouchPoints > 0 || settings.trigger == "click"){
                $(".menuzord-menu > li > a, .menuzord-menu ul.dropdown li a").bind("click touchstart", function(e){
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).parent("li").siblings("li").find(".dropdown, .megamenu").stop(true, true).fadeOut(300);
                    if($(this).siblings(".dropdown, .megamenu").css("display") == "none"){
                        showDropdown($(this).parent("li"));
                        return false;
                    }
                    else{
                        hideDropdown($(this).parent("li"));
                    }
                    window.location.href = $(this).attr("href");
                });
                $(document).bind("click.menu touchstart.menu", function(ev){
                    if($(ev.target).closest(".menuzord").length == 0){
                        $(".menuzord-menu").find(".dropdown, .megamenu").fadeOut(300);
                    }
                });
            }
            else{
                $(menu_li).bind("mouseenter", function(){
                    showDropdown(this);
                }).bind("mouseleave", function(){
                    hideDropdown(this);
                });
            }
        }

        // portrait mode
        function portraitMode(){
            $(menu).find(".dropdown, .megamenu").hide(0);
            $(menu).find(".indicator").each(function(){
                if($(this).parent("a").siblings(".dropdown, .megamenu").length > 0){
                    $(this).bind("click", function(e){
                        $(menu).scrollTo({top: 45, left: 0}, 600);
                        if($(this).parent().prop("tagName") == "A"){
                            e.preventDefault();
                        }
                        if($(this).parent("a").siblings(".dropdown, .megamenu").css("display") == "none"){
                            $(this).parent("a").siblings(".dropdown, .megamenu").delay(settings.showDelay).slideDown(settings.showSpeed);
                            $(this).parent("a").parent("li").siblings("li").find(".dropdown, .megamenu").slideUp(settings.hideSpeed);
                        }
                        else{
                            $(this).parent("a").siblings(".dropdown, .megamenu").slideUp(settings.hideSpeed);
                        }
                    });
                }
            });
        }

        // Fix the submenu on the right side
        function fixSubmenuRight(){
            var submenus = $(menu).children("li").children(".dropdown, .megamenu");
            if($(window).innerWidth() > mobileWidthBase){
                var menu_width = $(menu_container).outerWidth(true);
                for(var i = 0; i < submenus.length; i++){
                    if($(submenus[i]).parent("li").position().left + $(submenus[i]).outerWidth() > menu_width){
                        $(submenus[i]).css("right", 0);
                    }
                    else{
                        if(menu_width == $(submenus[i]).outerWidth() || (menu_width - $(submenus[i]).outerWidth()) < 20){
                            $(submenus[i]).css("left", 0);
                        }
                        if($(submenus[i]).parent("li").position().left + $(submenus[i]).outerWidth() < menu_width){
                            $(submenus[i]).css("right", "auto");
                        }
                    }
                }
            }
        }

        // show the bar to show/hide menu items on mobile
        function showMobileBar(){
            $(menu).hide(0);
            $(showHideButton).show(0).click(function(){
                if($(menu).css("display") == "none")
                    $(menu).slideDown(settings.showSpeed);
                else
                    $(menu).slideUp(settings.hideSpeed).find(".dropdown, .megamenu").hide(settings.hideSpeed);
            });
        }

        // hide the bar to show/hide menu items on mobile
        function hideMobileBar(){
            $(menu).show(0);
            $(showHideButton).hide(0);
        }

        // unbind events
        function unbindEvents(){
            $(menu_container).find("li, a").unbind();
            $(document).unbind("click.menu touchstart.menu");
        }

        // Menuzord tabs
        function menuTabs(){
            function startTab(tab){
                var TabNavs = $(tab).find(".menuzord-tabs-nav > li");
                var TabContents = $(tab).find(".menuzord-tabs-content");
                $(TabNavs).bind("click touchstart", function(e){
                    e.stopPropagation();
                    e.preventDefault();
                    $(TabNavs).removeClass("active");
                    $(this).addClass("active");
                    $(TabContents).hide(0);
                    $(TabContents[$(this).index()]).show(0);
                });
            }
            if($(menu).find(".menuzord-tabs").length > 0){
                var menuTabs = $(menu).find(".menuzord-tabs");
                for(var i = 0; i < menuTabs.length; i++){
                    startTab(menuTabs[i]);
                }
            }
        }

        // return window's width
        function windowWidth(){
            return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        }

        // navigation start function
        function startMenu(){
            fixSubmenuRight();
            if(windowWidth() <= mobileWidthBase && bigScreenFlag > mobileWidthBase){
                unbindEvents();
                if(settings.responsive){
                    showMobileBar();
                    portraitMode();
                }
                else{
                    landscapeMode();
                }
            }
            if(windowWidth() > mobileWidthBase && smallScreenFlag <= mobileWidthBase){
                unbindEvents();
                hideMobileBar();
                landscapeMode();
            }
            bigScreenFlag = windowWidth();
            smallScreenFlag = windowWidth();
            menuTabs();
            /* IE8 fix */
            if(/MSIE (\d+\.\d+);/.test(navigator.userAgent) && windowWidth() < mobileWidthBase){
                var ieversion = new Number(RegExp.$1);
                if(ieversion == 8){
                    $(showHideButton).hide(0);
                    $(menu).show(0);
                    unbindEvents();
                    landscapeMode();
                }
            }
        }

        startMenu();
        $(window).resize(function(){
            var t = setTimeout(function() {
                startMenu();
            }, 400);
            fixSubmenuRight();
        });

    }
}(jQuery));

jQuery(document).ready(function(){
    jQuery("#menuzord").menuzord({
        align: "right"
    });



});


let nav = $("#nav");
let navToggle = $("#navToggle");

jQuery(function($) {
    let size = 72;
    $('a[href*="#"]:not([href="#"])').click(function() {
        var target = $(this.hash);
        $('html,body').stop().animate({scrollTop: target.offset().top - size}, 'linear');
        //if mobile
        if (window.innerWidth < 768) {
            document.getElementById('nav').style.display = "none";
        }

    });
    if (location.hash){
        var id = $(location.hash);
    }
    $(window).on('load', function() {
        if (location.hash){
            $('html,body').stop().animate({scrollTop: target.offset().top - size}, 'linear');

            //if mobile
            if (window.innerWidth < 768) {
                document.getElementById('nav').style.display = "none";
            }
        };
    });


});


$(document).ready(function () {
    $(".facebook_share_link").click(function (e) {
            e.preventDefault();
            var t = $(this).attr("href");
            "javascript:;" != $(this).attr("href") && $(this).attr("href") || (t = document.URL);
            window.open("https://www.facebook.com/sharer/sharer.php?u=" + t, "facebook-popup", "height=350,width=600")
        }
    ),
        $(".x_share_link").click(function (e) {
                e.preventDefault();
                var t = $(this).attr("href");
                "javascript:;" != $(this).attr("href") && $(this).attr("href") || (t = document.URL);
                window.open("https://twitter.com/share?url=" + t, "twitter-popup", "height=350,width=600")
            }
        ),
        $(".linkedin_share_link").click(function (e) {
                e.preventDefault();
                var t = $(this).attr("href");
                "javascript:;" != $(this).attr("href") && $(this).attr("href") || (t = document.URL);
                window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + t, "lnkedin-popup", "height=350,width=600")
            }
        ),
        $("#js-copy-link").click(function (e) {
                e.preventDefault();
                var t = $(this).attr("href");
                "javascript:;" != $(this).attr("href") && $(this).attr("href") || (t = document.URL),
                    navigator.clipboard.writeText(t);
                var i = $(".toast_message").clone();
                i.html("Paylaşmanız için link kopyalandı."),
                    $(this).closest(".c-sidebar__share").append(i.addClass("show_toast")),
                    $(".show_toast").fadeIn(),
                    setTimeout(function () {
                            $(".show_toast").fadeOut()
                        }
                        , 1500)
            }
        ),
        $("#js-copy-link-mobile").click(function (e) {
                e.preventDefault();
                var t = $(this).attr("href");
                "javascript:;" != $(this).attr("href") && $(this).attr("href") || (t = document.URL),
                    navigator.clipboard.writeText(t);
                var i = $(".toast_message").clone();
                i.html("Paylaşmanız için link kopyalandı."),
                    $(this).closest(".c-sidebar__share").append(i.addClass("show_toast")),
                    $(".show_toast").fadeIn(),
                    setTimeout(function () {
                            $(".show_toast").fadeOut()
                        }
                        , 1500)
            }
        )
});
